import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/login'
	},
	{
		path: '/login',
		component: () => import('../views/login.vue'),
		meta: {
			title: 'SmartWe PMS'
		}
	},
	{
		path: '/preview',
		component: () => import('../views/hotel/hoteldetail.vue'),
		name: 'preview'
	},
	{
		path: '/viewcleantask',
		component: () => import('../views/order/viewcleantask.vue'),
		name: 'viewcleantask',
		meta: {
			content:{
				viewport:'width=device-width,initial-scale=1.0',
			},
		}
	},
	// {
	// 	path: '/cancelpage',
	// 	component: () => import('../views/order/cancelpage.vue'),
	// 	name:'cancelpage'
	// },
	{
		path: '/home',
		component: () => import('../views/home.vue'),
		children: [
			{
				path: '/index',
				component: () => import('../views/order/calendar.vue'),
				name: 'index'
			},
			{
				path: '/report',
				component: () => import('../views/dataAnalysis/report.vue'),
				name: 'report'
			},
			{
				path: '/weekreport',
				component: () => import('../views/dataAnalysis/weekreport.vue'),
				name: 'weekreport'
			},
			{
				path: '/label',
				component: () => import('../views/setting/label.vue'),
				name: 'label'
			},
			{
				path: '/basicinfo',
				component: () => import('../views/setting/basicinfo.vue'),
				name: 'basicinfo'
			},
			{
				path: '/sync',
				component: () => import('../views/setting/sync.vue'),
				name: 'sync'
			},
			{
				path: '/hotelList',
				component: () => import('../views/hotel/list.vue'),
				name: 'hotelList'
			},
			{
				path: '/hoteldiscount',
				component: () => import('../views/hotel/discount.vue'),
				name: 'hoteldiscount'
			},
			{
				path: '/roomtype',
				component: () => import('../views/hotel/roomtype.vue'),
				name: 'roomtype'
			},
			{
				path: '/feeplan',
				component: () => import('../views/hotel/feeplan.vue'),
				name: 'feeplan'
			},
			{
				path: '/orderlist',
				component: () => import('../views/order/orderlist.vue'),
				name: 'orderlist'
			},
			{
				path: '/distributionOrder',
				component: () => import('../views/order/distributionOrder.vue'),
				name: 'distributionOrder'
			},
			{
				path: '/financelist',
				component: () => import('../views/order/financelist.vue'),
				name: 'financelist'
			},
			{
				path: '/checkroom',
				component: () => import('../views/order/checkroom.vue'),
				name: 'checkroom'
			},
			{
				path: '/cleantask',
				component: () => import('../views/order/cleantask.vue'),
				name: 'cleantask'
			},
			{
				path: '/calendar',
				component: () => import('../views/order/calendar.vue'),
				name: 'calendar'
			},
			{
				path: '/ordercalendar',
				component: () => import('../views/order/ordercalendar.vue'),
				name: 'ordercalendar'
			},
			{
				path:'/ordercalendar0',
				component: () => import('../views/order/ordercalendar0.vue'),
				name:'ordercalendar0'
			},
			{
				path:'/ordercalendar1',
				component: () => import('../views/order/ordercalendar1.vue'),
				name:'ordercalendar1'
			},
			{
				path: '/setdiscount',
				component: () => import('../views/setting/discount.vue'),
				name: 'setdiscount'
			},
			{
				path: '/pricetemplates',
				component: () => import('../views/hotel/pricetemplates.vue'),
				name: 'pricetemplates'
			},
			{
				path: '/pricetemplatedetail',
				component: () => import('../views/hotel/pricetemplatedetail.vue'),
				name: 'pricetemplatedetail'
			},
			{
				path: '/couponlist',
				component: () => import('../views/coupon/couponlist.vue'),
				name: 'couponlist'
			},
			{
				path: '/userlist',
				component: () => import('../views/coupon/userlist.vue'),
				name: 'userlist'
			},
			{
				path: '/emailtemp',
				component: () => import('../views/setting/hotelEmailtemp.vue'),
				name: 'emailtemp'
			},
			{
				path: '/userLabel',
				component: () => import('../views/coupon/userLabel.vue'),
				name: 'userLabel'
			},
			{
				path: '/pagetemplate',
				component: () => import('../views/setting/pagetemplate.vue'),
				name: 'pagetemplate'
			},
			{
				path: '/activelist',
				component: () => import('../views/activities/list.vue'),
				name: 'activelist'
			},
			{
				path: '/org',
				component: () => import('../views/setting/org.vue'),
				name: 'org'
			},
			{
				path: '/stockalert',
				component: () => import('../views/order/stockalert.vue'),
				name: 'stockalert'
			},
		]
	},
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
